/* global require */

export const IMAGES = {
  LOGO: require("../images/grt8-logo.png"),
  BIG_SYMBOL: require("../images/big-symbol.png"),
  TOP_CITY: require("../images/top-city.svg"),
  FOOTER_CITY: require("../images/footer-city.svg"),
  PROJECT_MOCKUP: require("../images/project-mockup.png"),
  MOBBY_MOCKUP: require("../images/mobby-mockup.png"),
  FOOTER_LOGO: require("../images/footer-logo.svg"),
  MOBBY: require("../images/mobby.svg"),
  MOBBY_DARK: require("../images/mobby-dark.svg"),
  MOBBY_SCHEME: require("../images/mobby-scheme.svg"),
  MOBBY_EFFECT: require("../images/mobby-effect.svg"),

  // Loaders
  CITY_LOGO_LOADER: require("../images/loaders/city-logo-loader.svg"),
  BIG_SYMBOL_LOADER: require("../images/loaders/big-symbol-loader.svg"),
  TOP_CITY_LOADER: require("../images/loaders/top-city-loader.svg"),
  MOBILE_LOADER: require("../images/loaders/mobile-loader.svg"),

  // Banners
  BANNER_DESKTOP: require("../images/mobby-candidato-banner-desktop.png"),
  BANNER_MOBILE: require("../images/mobby-candidato-banner-mobile.png")
}
