export const WELCOME = {
  TITLE: `
    Cidadão Conectado
  `,
  DESCRIPTION: `
    Investir na participação ativa do cidadão é um dos
    primeiros passos para gestões públicas que querem ter uma maior presença
    online, assim é possível divulgar a atuação do setor público, e solucionar as
    necessidades e demandas dos habitantes de forma mais assertiva.
  `,
  BAR_TITLE: `
    Nossa Missão
  `,
  BAR_DESCRIPTION: `
    A Grt8 Inovações e Negócios é uma empresa especializada no
    desenvolvimento de soluções tecnológicas baseadas na transformação digital para
    a Gestão Pública, com o objetivo de conectar o cidadão as instituições
    governamentais.
  `
}
