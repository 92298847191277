import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const NATAL = {
  COLOR: "#0174EF",
  ICON: "https://lh3.googleusercontent.com/rv5I95lpxuNQNrQc9lAZLFFx2dKq-eu-ou649_W8R4HKqSBri-a2BO8cSduamyNTPJE=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/ikbVBvB6bMscnIuxJfdo2_jW580PiX_SRm9KQUln_itjzbwuQL3aIokoh4nsjp1HUQ=w2560-h985-rw",

  URLAPI: "https://natal.api.grt8.com.br:4008",

  // Header

  TOP: `
    Prefeitura do Natal Apresenta:
  `,
  TITLE: `
    Natal Digital
  `,
  DESCRIPTION: `
    O aplicativo Natal Digital possui diversos recursos disponíveis na
    palma da mão, visando facilitar a vivência do cidadão e a excelência na gestão
    pública.
  `,
  SLOGAN: `
    Prefeitura do natal, a nossa cidade.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      Baixe já
    `,
    DESCRIPTION: `
      O aplicativo Natal Digitial está disponível nas plataformas Android e IOS.
    `,
    ANDROID: {
      TITLE: "Baixe na Google Play",
      LINK: "https://play.google.com/store/apps/details?id=com.br.grt8.natal"
    },
    IOS: {
      TITLE: "Baixe na App Store",
      LINK: "https://apps.apple.com/br/app/natal-digital/id1496609705"
    },
  },

  FEATURES: {
    TITLE: `
      Módulos e funcionalidades
    `,
    DESCRIPTION: `
      Confira todas as funcionalidades disponíveis
      no aplicativo.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Notícias",
        TEXT: `
          Fique atualizado sobre todas as notícias que estão acontecendo
          na sua cidade em formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          No aplicativo o cidadão pode relatar diversos tipos de
          ocorrências como: defesa civil, serviços urbanos, setor público, saúde,
          processos administrativos e ouvidoria.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Mapa da cidade",
        TEXT: `
          Oriente-se sobre os pontos turístico, hospitais, postos
          policiais e outros mais. Todos os pontos são georreferenciados e com
          informações detalhadas para melhor informatividade do cidadão.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Fique atualizado sobre as datas e informações dos eventos e
          atrações da cidade.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Registre e compartilhe sua selfie em eventos e datas
          comemorativas do município.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Natal Digital é um projeto desenvolvido pela Mobby - GRT8
    `
  }
}
