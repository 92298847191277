import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const CARAUBAS = {
  COLOR: "#B7333A",
  ICON: "https://lh3.googleusercontent.com/NkOGNAFQkd76_py9oI7z4l3bzF0KFt0W-Tka-iTpsPeglsQek-x9U4XIFoGrqadJL7U=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/WAA3wH87OHl_FykNXnGEcbwTwFgXvhOa1p4tXKCl5b68YpfSgT0HZsSs0BSGNwhMHN0=w2560-h985-rw",

  URLAPI: "https://caraubas.api.grt8.com.br:4014",

  // Header

  TOP: `
    Prefeitura de Caraúbas:
  `,
  TITLE: `
    Caraúbas Conectada
  `,
  DESCRIPTION: `
    O aplicativo Caraúbas Conectada possui diversos recursos disponíveis na
    palma da mão, visando facilitar a vivência do cidadão e a excelência na gestão
    pública.
  `,
  SLOGAN: `
    É tempo de avançar.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      Baixe já
    `,
    DESCRIPTION: `
      O aplicativo Caraúbas Conectada está disponível nas plataformas Android e IOS.
    `,
    ANDROID: {
      TITLE: "Baixe na Google Play",
      LINK: "https://play.google.com/store/apps/details?id=br.com.grt8.caraubasrn"
    },
    IOS: {
      TITLE: "Baixe na App Store",
      LINK: null
    },
  },

  FEATURES: {
    TITLE: `
      Módulos e funcionalidades
    `,
    DESCRIPTION: `
      Confira todas as funcionalidades disponíveis
      no aplicativo.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Notícias",
        TEXT: `
          Fique atualizado sobre todas as notícias que estão acontecendo
          na sua cidade em formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          No aplicativo o cidadão pode relatar diversos tipos de
          ocorrências como: defesa civil, serviços urbanos, setor público, saúde,
          processos administrativos e ouvidoria.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Mapa da cidade",
        TEXT: `
          Oriente-se sobre os pontos turístico, hospitais, postos
          policiais e outros mais. Todos os pontos são georreferenciados e com
          informações detalhadas para melhor informatividade do cidadão.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Fique atualizado sobre as datas e informações dos eventos e
          atrações da cidade.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Registre e compartilhe sua selfie em eventos e datas
          comemorativas do município.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Caraúbas Conectada é um projeto desenvolvido pela Mobby - GRT8
    `
  }
}
