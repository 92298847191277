export const COLORS = {
  // Colors
  PRIMARY: "#6F3587",
  SECONDARY: "#C72882",
  TERCIARY: "#A33896",
  WHITE: "#FFFFFF",

  // Text
  FONT: "#616161",
  TITLE: "#1B1B1B",

  // Border
  BORDER: "#D5D5D5",

  // Apps
  MOBBY: "#4B24C9",
}
