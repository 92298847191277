import {
  FaNewspaper,
  FaHardHat,
  FaSearch,
  FaShieldAlt,
  FaAppleAlt,
  FaUserTie,
  FaUserFriends,
  FaPodcast,
  FaCloudRain
} from "react-icons/fa"

export const MOBBY = {
  DETAILS: "Ver projeto em detalhes",

  DESCRIPTION_HOME: `
    Com um alto padrão administrativo, inovação e tecnologia, a
    Mobby Smart Cities é um projeto único que incentiva a participação ativa do
    cidadão juntamente com a administração pública buscando a melhoria no convívio
    humano. Segue as principais características do produto Mobby:
  `,

  DESCRIPTION_PAGE: `
    Muito mais do que um aplicativo para mostrar a página da internet, o
    projeto Mobby Smart Cities é um conjunto de soluções digitais para as diversas
    áreas da gestão pública, como também é a transformação digital para o cidadão
    diretamente do smartphone com as seguintes características:
  `,

  FEATURES: [
    `Aplicativo exclusivo e customizado com imagem,
    nome do projeto e demais desejos de acordo com a Prefeitura.`,

    `Disponível para as lojas de aplicativos
    Google Play (Android) e App Store (iOS) de forma gratuita para os
    cidadãos.`,

    `A plataforma de retaguarda possui diversos recursos, dentre
    eles estão: atendimento em tempo real, cadastro de notícias e
    informações sobre o município, criação de eventos, enquetes e outras
    funcionalidades.`,

    `Os serviços são definidos com a prefeitura de acordo
    com suas estratégias de gestão e os objetivos a serem alcançados com a
    plataforma.`
  ],

  MODULES: {
    ITEMS: [
      {
        TITLE: "Notícias",
        ICON: FaNewspaper,
        CONTENT: `Objetivando a boa informatividade e notícias atualizadas da
        prefeitura, o aplicativo Mobby Smart Cities conta com um feed de notícias.
        Ademais, é enviado uma mensagem em push para o celular do cidadão
        informando que acaba de ser publicada uma nova notícia, ao clicar na
        mensagem o aplicativo é aberto instantaneamente com a publicação em
        questão.`
      },
      {
        TITLE: "Obras da Gestão",
        ICON: FaHardHat,
        CONTENT: `Para manter o cidadão atualizado, as obras de gestão
        são fotografadas e georreferenciadas. Além disso, as obras podem ser
        mostradas de forma animada onde o cidadão pode fazer um passeio
        virtual sobre a cidade, visualizando de forma única os benefícios da gestão
        do município.`
      },
      {
        TITLE: "Pesquisas",
        ICON: FaSearch,
        CONTENT: `A plataforma permite enviar pesquisas e enquetes sobre as
        mais variadas decisões da cidade, possibilitando a participação do cidadão
        nessas resoluções.`
      },
      {
        TITLE: "Segurança Pública",
        ICON: FaShieldAlt,
        CONTENT: `Por meio do aplicativo é realizar protocolos de
        ocorrências, como acidentes, por exemplo, os quais serão atendidos em
        tempo real. Ademais, afim de facilitar o atendimento, a ocorrência vem
        detalhada com foto, endereço e outras descrições dependendo do tipo de
        protocolo.`
      },
      {
        TITLE: "Feirinha virtual",
        ICON: FaAppleAlt,
        CONTENT: `A aba da feirinha virtual é um meio de ajudar o
        pequeno empreendedor do município, facilitando a exposição e
        divulgação com foto e descrição de seus produtos, além disso, é possível
        ver outras diversas oportunidades de compra.`
      },
      {
        TITLE: "Vagas de Emprego",
        ICON: FaUserTie,
        CONTENT: `Módulo com funções de cadastro do perfil
        profissional do cidadão com possibilidade de anexar o currículo indicando
        a sua área profissional (banco de talentos). Ademais, também inclui
        módulo para as empresas cadastrarem novas vagas de emprego que
        podem ser visualizadas pelo cidadão por meio do aplicativo do município.`
      },
      {
        TITLE: "Assistência Social",
        ICON: FaUserFriends,
        CONTENT: `Módulo com funções de cadastro de solicitações e
        pedidos, afim de registrar, organizar e atender o cidadão.`
      },
      {
        TITLE: "Fala Prefeito",
        ICON: FaPodcast,
        CONTENT: `Canal de comunicação do Prefeito pelo aplicativo Mobby
        Smart Cities. O aplicativo informa que o Prefeito vai estar na rádio em
        contagem regressiva. Além da rádio, o cidadão pode acessar o canal de
        podcast, vídeos da prefeitura e mandar mensagens ao Prefeito.`
      },
      {
        TITLE: "Monitoramento Meteorológico",
        ICON: FaCloudRain,
        CONTENT: `A plataforma inclui monitoramento por
        meio de sensores dos seguintes indicadores: temperatura, umidade, Índice
        de Radiação Ultravioleta, medidor pluviométrico e medição de
        reservatório.`
      }
    ]
  },

  CITIES: [
    {
      NAME: "Mossoró - RN",
      IMAGE: require("../images/cities/mossoro-logo.jpg"),
      LINK: "/mossoro-conectada"
    },

    {
      NAME: "Alto do Rodrigues - RN",
      IMAGE: require("../images/cities/alto-logo.png"),
      LINK: "/alto-digital"
    },

    {
      NAME: "Baraúna - RN",
      IMAGE: require("../images/cities/barauna-logo.png"),
      LINK: "/barauna-digital"
    },

    {
      NAME: "Natal - RN",
      IMAGE: require("../images/cities/natal-logo.jpg"),
      LINK: "/natal-digital"
    },

    {
      NAME: "Caraúbas - RN",
      IMAGE: require("../images/cities/caraubas-logo.jpg"),
      LINK: "/caraubas-conectada"
    },

    {
      NAME: "Lagoa Nova - RN",
      IMAGE: require("../images/cities/lagoa-nova-logo.jpg"),
      LINK: "/lagoa-nova-digital"
    },

    {
      NAME: "Pendências - RN",
      IMAGE: require("../images/cities/pendencias-logo.jpg"),
      LINK: "/pendencias-digital"
    },

    {
      NAME: "Torrecera - Espanha",
      IMAGE: require("../images/cities/torrecera-logo.jpg"),
      LINK: "/torrecera-conecta"
    }
  ]
}
