import { FaEye, FaRoute, FaLeaf, FaUsers } from "react-icons/fa"
import { COLORS } from "../constants"

export const FEATURES = {
  TITLE: `
    Cidades inteligentes
  `,
  DESCRIPTION: `
    O conceito de cidade inteligente integra a Tecnologia da
    Informação e Comunicação, o cidadão e a administração pública, afim de otimizar
    a eficiência dos serviços e operações das cidades por meio das soluções
    tecnológicas.
  `,
  ITEMS: [
    {
      ICON: FaUsers,
      COLOR: COLORS.PRIMARY,
      TITLE: "Engajamento",
      TEXT: `
        A participação cidadã tem um impacto significativo, pois
        engloba diferentes formas de atuação: social, política, cultural, ambiental
        e econômica.
      `
    },
    {
      ICON: FaEye,
      COLOR: COLORS.SECONDARY,
      TITLE: "Transparência",
      TEXT: `
        Boa comunicação e transparência é de suma importância,
        visto que aproxima a população das ações governamentais, fortalecendo o
        ideal de representatividade do governante.
      `
    },
    {
      ICON: FaRoute,
      COLOR: COLORS.TERCIARY,
      TITLE: "Mobilidade",
      TEXT: `
        Cidades Inteligentes incorporam a tecnologia como forma de
        melhorar o trânsito, acessibilidade e meios de transporte, objetivando
        facilitar a vida dos cidadãos e permitir fluidez na movimentação urbana.
      `
    },
    {
      ICON: FaLeaf,
      COLOR: COLORS.PRIMARY,
      TITLE: "Sustentabilidade",
      TEXT: `
        Implementar soluções e medidas para um melhor
        aproveitamento dos recursos naturais, diminuição da poluição e
        sustentabilidade fazem parte do processo de transformação das Cidades
        Digitais.
      `
    }
  ]
}