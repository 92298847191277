import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

export function SEO({ description, lang, title, themeColor, author, imagem }) {
  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "theme-color",
          content: themeColor,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:image",
          content: imagem,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: "pt-br",
  meta: [],
  description: "GRT8 - Inovação e Tecnologia",
  title: "GRT8",
  author: "Carlos Costa",
  imagem: ""
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  imagem: PropTypes.string,
}
