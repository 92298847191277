import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const ALTO = {
  COLOR: "#2F6CCA",
  ICON: "https://lh3.googleusercontent.com/tlLtYuECCWesUHFFuq6KKFCGGKmmAPLdfKUdgobPIeucb5N8kw7Mg9_2__tfsr5HAiw=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/29devnEvXlJNLaOOQsm6RHAl9hicdNVZVVnsib7SJYsfkTdfTi-wt0KRfk1C0SiNMEI=w2560-h985-rw",

  URLAPI: "https://altorodrigues.api.grt8.com.br:4009",

  // Header

  TOP: `
    Prefeitura do Alto do Rodrigues:
  `,
  TITLE: `
    Alto do Rodrigues
  `,
  DESCRIPTION: `
    O aplicativo Alto Digital possui diversos recursos disponíveis na
    palma da mão, visando facilitar a vivência do cidadão e a excelência na gestão
    pública.
  `,
  SLOGAN: `
    Alto. Aqui tem trabalho.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      Baixe já
    `,
    DESCRIPTION: `
      O aplicativo Alto Digitial está disponível nas plataformas Android e IOS.
    `,
    ANDROID: {
      TITLE: "Baixe na Google Play",
      LINK: "https://play.google.com/store/apps/details?id=com.br.grt8.alto.rodrigues"
    },
    IOS: {
      TITLE: "Baixe na App Store",
      LINK: null
    },
  },

  FEATURES: {
    TITLE: `
      Módulos e funcionalidades
    `,
    DESCRIPTION: `
      Confira todas as funcionalidades disponíveis no aplicativo.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Notícias",
        TEXT: `
          Fique atualizado sobre todas as notícias que estão acontecendo
          na sua cidade em formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          No aplicativo o cidadão pode relatar diversos tipos de
          ocorrências como: defesa civil, serviços urbanos, setor público, saúde,
          processos administrativos e ouvidoria.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Mapa da cidade",
        TEXT: `
          Oriente-se sobre os pontos turístico, hospitais, postos
          policiais e outros mais. Todos os pontos são georreferenciados e com
          informações detalhadas para melhor informatividade do cidadão.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Fique atualizado sobre as datas e informações dos eventos e
          atrações da cidade.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Registre e compartilhe sua selfie em eventos e datas
          comemorativas do município.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Alto digital é um projeto desenvolvido pela Mobby - GRT8
    `
  }
}
