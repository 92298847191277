import React from "react"
import styled from "styled-components"
import { IMAGES, COLORS } from "../constants"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"

const HeaderWrapper = styled.header`
  border-bottom: solid ${COLORS.BORDER} 1px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  justify-content: center;
`

const Logo = styled(Link)`
  width: 80px;
  position: relative;

  img{
    width: 100%;
  }
`

export function Header(){
  return (
    <HeaderWrapper>
      <Logo to="/">
        <LazyLoad>
          <img src={IMAGES.LOGO} alt="logo" />
        </LazyLoad>
      </Logo>
    </HeaderWrapper>
  )
}
