import styled, {css} from "styled-components"
import {COLORS} from "../constants"

export const SectionTitle = styled.h1`
  font-size: 4em;
  text-align: center;
  color: ${COLORS.TITLE};
  font-weight: 300;
  margin: 0 auto 40px auto;
  width: 100%;
  max-width: 800px;

  @media(max-width: 800px){
    font-size: 2.5em;
  }

  ${props => props.color && css`
    color: ${props.color}
  `}
`

export const P = styled.p`
  font-size: 1.5em;

  @media(max-width: 750px){
    font-size: 1.2em;
  }

  ${props => props.small && css`
    font-size: 1.2em;

    @media(max-width: 750px){
      font-size: 1em;
    }
  `}
`

export const H = styled.h1`
  font-size: 1.6em;
  font-weight: 500;

  @media(max-width: 750px){
    font-size: 1.2em;
  }
`
