import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const MOSSORO = {
  COLOR: "#0093BF",
  ICON: "https://lh3.googleusercontent.com/1VuD8eevBRcBh-D4lZKftVBAChwN0S310IRvRZTUvbdD5auWWpeBxGY_iCWKktOYLP6f=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/ZsPgrxRizNq8KbwKiORAhAbG1BjJ0eecB7AiUt4gzrnJq-0FrsMUO0kYOQSFMlvwf-8=w2560-h985-rw",

  URLAPI: "https://mossoro.api.grt8.com.br:4005",

  // Header

  TOP: `
    Prefeitura de Mossoró:
  `,
  TITLE: `
    Mossoró Conectada
  `,
  DESCRIPTION: `
    O aplicativo Mossoró Conectada possui diversos recursos disponíveis na
    palma da mão, visando facilitar a vivência do cidadão e a excelência na gestão
    pública.
  `,
  SLOGAN: `
    Todos por Mossoró.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      Baixe já
    `,
    DESCRIPTION: `
      O aplicativo Mossoró Conectada está disponível nas plataformas Android e IOS.
    `,
    ANDROID: {
      TITLE: "Baixe na Google Play",
      LINK: "https://play.google.com/store/apps/details?id=com.cgsconsultoria.mossoro"
    },
    IOS: {
      TITLE: "Baixe na App Store",
      LINK: "https://apps.apple.com/br/app/mossoró-conectada/id1459505160"
    },
  },

  FEATURES: {
    TITLE: `
      Módulos e funcionalidades
    `,
    DESCRIPTION: `
      Confira todas as funcionalidades disponíveis
      no aplicativo.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Notícias",
        TEXT: `
          Fique atualizado sobre todas as notícias que estão acontecendo
          na sua cidade em formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          No aplicativo o cidadão pode relatar diversos tipos de
          ocorrências como: defesa civil, serviços urbanos, setor público, saúde,
          processos administrativos e ouvidoria.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Mapa da cidade",
        TEXT: `
          Oriente-se sobre os pontos turístico, hospitais, postos
          policiais e outros mais. Todos os pontos são georreferenciados e com
          informações detalhadas para melhor informatividade do cidadão.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Fique atualizado sobre as datas e informações dos eventos e
          atrações da cidade.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Registre e compartilhe sua selfie em eventos e datas
          comemorativas do município.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Mossoró Conectada é um projeto desenvolvido pela Mobby - GRT8
    `
  }
}
