import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const TORRECERA = {
  COLOR: "#045985",
  ICON: "https://lh3.googleusercontent.com/mqKHFKR-O6uf9aM3fOHSHcUeRzLoKAUnTSExWv8M7oXStUSLHDn3nfDxRwf_zKRrWSs=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/HFrmQoLNptYDxdyU4UtQFHiBj6g-64qCjKoEeFQ8ArQUduuM6FerxtL5D_YSDGyigw=w2560-h985-rw",

  URLAPI: "https://torrecera.api.grt8.com.br:4007",

  // Header

  TOP: `
    Torrecera - Ayuntamiento de Jerez:
  `,
  TITLE: `
    Torrecera Conecta
  `,
  DESCRIPTION: `
    La aplicación Torrecera Conecta tiene varios recursos disponibles en la
    palma de su mano, con el objetivo de facilitar la experiencia del ciudadano y la
    excelencia en la gestión pública.`,
  SLOGAN: `
    Torrecera Conecta.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      ¡Obtener la aplicación!
    `,
    DESCRIPTION: `
      La aplicación Torrecera Conecta disponible en las tiendas de aplicaciones Google Play (Android) y App Store (iOS)
    `,
    ANDROID: {
      TITLE: "Descargar en Google Play",
      LINK: "https://play.google.com/store/apps/details?id=br.com.grt8.torrecera"
    },
    IOS: {
      TITLE: "Descargar en App Store",
      LINK: null
    },
  },

  FEATURES: {
    TITLE: `
      Módulos y características
    `,
    DESCRIPTION: `
      Consulte todas las funciones disponibles en la aplicación.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Noticias",
        TEXT: `
          Manténgase actualizado sobre todas las noticias que están sucediendo en su ciudad en formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          En la solicitud, el ciudadano puede informar varios tipos de sucesos,
          tales como: defensa civil, servicios urbanos, sector público, salud,
          procesos administrativos y defensor del pueblo.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Plano de la ciudad",
        TEXT: `
          Infórmese sobre lugares turísticos, hospitales, estaciones de policía y más.
          Todos los puntos están georreferenciados y con información detallada
          para una mejor información ciudadana.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Manténgase actualizado sobre las fechas y la
          información de los eventos y atracciones de la ciudad.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Regístrese y comparta su selfie en
          eventos y fechas conmemorativas en el municipio.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Torrecera Conecta es un proyecto desarrollado por Mobby - GRT8
    `
  }
}
