import {
  FaNewspaper,
  FaFile,
  FaMap,
  FaCalendarAlt,
  FaPortrait
} from "react-icons/fa"

export const LAGOA = {
  COLOR: "#2055AB",
  ICON: "https://lh3.googleusercontent.com/kpNfM0i3Y7QtSbVwlhJ-AgHrrjTHbE9qD9dvSpAFkEILzoycRdlgF-MjHUBb8etqaLzo=s180-rw",
  MOCKUP: "https://lh3.googleusercontent.com/a3f39tN0mWOM_U5EuRZA3GHi_QZsWRxcjycJoFQvrGHsZKkS1yA8OR6DxoS2501VQA=w2560-h985-rw",

  URLAPI: "https://lagoanova.api.grt8.com.br:4012",

  // Header

  TOP: `
    Prefeitura de Lagoa Nova:
  `,
  TITLE: `
    Lagoa Nova Digital
  `,
  DESCRIPTION: `
    O aplicativo Lagoa Nova Digital possui diversos recursos disponíveis na
    palma da mão, visando facilitar a vivência do cidadão e a excelência na gestão
    pública.
  `,
  SLOGAN: `
    Todos pela nossa cidade.
  `,

  // Center

  DOWNLOAD: {
    TITLE: `
      Baixe já
    `,
    DESCRIPTION: `
      O aplicativo Lagoa Nova Digital está disponível nas plataformas Android e IOS.
    `,
    ANDROID: {
      TITLE: "Baixe na Google Play",
      LINK: "https://play.google.com/store/apps/details?id=br.com.grt8.lagoanova"
    },
    IOS: {
      TITLE: "Baixe na App Store",
      LINK: null
    },
  },

  FEATURES: {
    TITLE: `
      Módulos e funcionalidades
    `,
    DESCRIPTION: `
      Confira todas as funcionalidades disponíveis
      no aplicativo.
    `,
    ITEMS :[
      {
        ICON: FaNewspaper,
        TITLE: "Notícias",
        TEXT: `
          Fique atualizado sobre todas as notícias que estão acontecendo
          na sua cidade em formato de feed.
        `
      },
      {
        ICON: FaFile,
        TITLE: "Protocolos",
        TEXT: `
          No aplicativo o cidadão pode relatar diversos tipos de
          ocorrências como: defesa civil, serviços urbanos, setor público, saúde,
          processos administrativos e ouvidoria.
        `
      },
      {
        ICON: FaMap,
        TITLE: "Mapa da cidade",
        TEXT: `
          Oriente-se sobre os pontos turístico, hospitais, postos
          policiais e outros mais. Todos os pontos são georreferenciados e com
          informações detalhadas para melhor informatividade do cidadão.
        `
      },
      {
        ICON: FaCalendarAlt,
        TITLE: "Eventos",
        TEXT: `
          Fique atualizado sobre as datas e informações dos eventos e
          atrações da cidade.
        `
      },
      {
        ICON: FaPortrait,
        TITLE: "Selfies",
        TEXT: `
          Registre e compartilhe sua selfie em eventos e datas
          comemorativas do município.
        `
      }
    ]
  },

  // Credits

  CREDITS: {
    DESCRIPTION: `
      Lagoa Nova Digital é um projeto desenvolvido pela Mobby - GRT8
    `
  }
}
