import React from "react"
import Particles from "react-particles-js"
import styled from "styled-components"

export function Effect({ pointColor, lineColor, area }){
  const EffectContent = styled(Particles)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    @media(max-width: 750px){
      width: 170%;
      height: 170%;
    }
  `

  const config = {
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          value_area: area
        }
      },
      size: {
        value: 3
      },
      color: {
        value: pointColor
      },
      line_linked: {
        color: lineColor
      }
    }
  }

  return (<EffectContent params={config} />)
}

Effect.defaultProps = {
  pointColor: "#fff",
  lineColor: "#fff",
  area: 1000
}
