import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants"

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  background: #fff;
  color: ${COLORS.FONT};
  font-weight: 500;
  font-size: 1em;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  position: relative;
  border:none;
  font-family: inherit;
  transition: all 0.2s linear 0s;

  a{
    text-decoration: none;
    color: ${COLORS.FONT};
  }

  &:hover{
    box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.4);
  }
`

export function Button(props){
  const ButtonIcon = styled(props.icon)`
    color: ${COLORS.SECONDARY};
    margin-right: 10px;
    font-size: 25px;
  `

  return(
    <ButtonWrapper {...props} >
      { props.icon && <ButtonIcon/> }
      <span> {props.children} </span>
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  children: "Button"
}
