import React, {useState, useEffect} from "react"
import styled, {css} from "styled-components"
import {IMAGES} from "../constants"

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);  
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Content = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 855px;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  text-decoration: none;
  
  ${props => props.isMobile && css`
    max-width: 280px;
    width: 100%;
  `}
`

const Link = styled.a`
  display: block;
  border-top: solid #e3e3e3 1px;
`

const Image = styled.img`
  border-radius: 5px;
  width: 100%;
`

const Close = styled.button`
  border: none;
  background: red;
  color: white;
  margin: 10px;
  padding: 5px 10px;
  font-family: inherit;
  font-weight: 500;
  border-radius: 3px;
  align-self: flex-end;

  &:hover{
    background: black;
  }
`

export function Banner(){
  const [visible, setVisible] = useState(true)
  const [storage, setStorage] = useState(null)

  let image = IMAGES.BANNER_DESKTOP
  let isMobile = false
  let vw = typeof document !== `undefined` ? Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) : 900
  let firstTime = typeof window !== 'undefined' && localStorage.getItem("firstTime")
  const condition = 

  useEffect(() => {
    setStorage(typeof document !== 'undefined' ? localStorage : null)
  })

  if(vw < 800){
    image = IMAGES.BANNER_MOBILE
    isMobile = true
  }

  function hidden(){
    setVisible(false)
    storage.setItem("firstTime", false)
  }

  return (
    <Wrapper onClick={hidden} key={visible} style={{ display: visible && (firstTime !== "false") ? "flex" : "none" }} >
      <Content isMobile={isMobile} target="_blank">
        <Close onClick={hidden}> Fechar </Close>
        <Link target="_blank" href="http://www.mobbycandidatos.com/">
          <Image src={image} alt="banner-desktop"/>
        </Link>
      </Content>
    </Wrapper>
  )
}