import styled from "styled-components"
import { COLORS } from "../constants"

export const Page = styled.section`
  min-height: 100vh;
`

export const Section = styled.section`
  padding: 170px 20px;
  background-color: ${COLORS.WHITE};
  position: relative;

  @media(max-width: 700px){
    padding-top: 70px;
    padding-bottom: 70px;
  }
`
