export const CONTACT = {
  TITLE: "Contato",

  // Form
  NAME_PLACEHOLDER: "— Digite seu nome",
  NAME_ERROR: "O nome é obrigatório",

  EMAIL_PLACEHOLDER: "— Digite seu email",
  EMAIL_ERROR: "O Email é obrigatório",

  CONTENT_PLACEHOLDER: "— Digite o conteúdo da mensagem",
  CONTENT_ERROR: "O Conteúdo é obrigatório",

  SUBMIT: "Enviar",

  // Credits
  ADDRESS: "Rua Carvão de Pedra, 11, Potilandia, Natal/RN, cep; 59.076-010",
  EMAIL_CONTACT: "comercial@grt8.com.br",
  PHONE: "+55(84) 3091-8559",
  MAP: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15877.35577464174!2d-35.2035919!3d-5.8077161!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x73a82f2812300476!2sTirol%20way%20office!5e0!3m2!1spt-BR!2sbr!4v1578086722604!5m2!1spt-BR!2sbr"
}
