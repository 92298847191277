import React from "react"
import styled from "styled-components"
import { IMAGES, COLORS } from "../constants"
import { CONTACT } from "../content"
import { P } from "."
import LazyLoad from "react-lazyload"

const Wrapper = styled.footer`
  padding: 150px 0 0 0;

  @media(max-width: 700px){
    padding: 50px 0 0 0;
  }
`

const Image = styled.img`
  border: solid ${COLORS.BORDER} 1px;
  width: 100%;
  max-width: 180px;
  display: block;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto 30px auto;
`

const Infos = styled.div`
  margin-bottom: 120px;

  @media(max-width: 700px){
    margin-bottom: 50px;
  }
`

const Info = styled(P)`
  text-align: center;
  color: ${COLORS.FONT};
  margin-bottom: 10px;

  @media(max-width: 700px){
    max-width: 250px;
    margin: 0 auto 10px auto;
  }
`

const City = styled.img`
  width: 100%;
  max-width: 900px;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 2px;
`

const Credits = styled(P)`
  background: -webkit-linear-gradient(45deg, ${COLORS.PRIMARY}, ${COLORS.SECONDARY});
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
  height: 70px;
  position: relative;
  z-index: 2px;
  color: #fff;
`

export function Footer(){
  return (
    <Wrapper>
      <LazyLoad height={200}>
        <Image src={IMAGES.FOOTER_LOGO}/>
        <Infos>
          <Info small> {CONTACT.ADDRESS} </Info>
          <Info small> {CONTACT.EMAIL_CONTACT} </Info>
          <Info small> {CONTACT.PHONE} </Info>
        </Infos>

        <City src={IMAGES.FOOTER_CITY} />

        <Credits small>
          GRT8 | 2020
        </Credits>
      </LazyLoad>
    </Wrapper>
  )
}